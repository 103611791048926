// 3rd-party imports

import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import styled, { createGlobalStyle } from "styled-components";

// component

const GlobalStyle = createGlobalStyle`

  html {
    background-color: #fff;
  }

  body,
  pre {
    font-family: "Roboto Mono", monospace;
  }

  body {
    font-size: 20px;
    word-wrap: break-word;
  }

  @media screen and (max-width: 425px) {
    body {
      /* 0.875 rem * 20px = 17.5px*/
      font-size: 17.5px;
    }
  }

  p {
    line-height: 1.5;
    margin: 32px 0;
  }

  hr {
    border: 0;
    height: 1px;
    background-color: #ccc;
  }
  a {
    color: #0074d9;
    text-decoration: underline;
  }
  a:hover {
    text-decoration: none;
  }
  li {
    margin-bottom: 10px;
    line-height: 1.15;

    > p {
      margin: 0;
      line-height: 1.15;
    }
  }
  ul li::before {
    content: "*";
    display: block;
    margin-left: -3ex;
    color: #000000;
    float: left;

    line-height: 1.15;
  }
  ul {
    margin-left: 30px;
    padding: 0px;
    list-style: none;
  }
  pre {
    background-color: #f6f8fa;
    border-radius: 3px;
    padding: 16px;
  }

`;

const ContentWrapper = styled.div`
  padding-left: 15px;
  padding-right: 15px;
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;

  padding-bottom: 45px;
`;

const Styles = () => {
  return (
    <React.Fragment>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Roboto+Mono:400,400i,500,500i,700,700i" rel="stylesheet" />
      </Helmet>
      <GlobalStyle />
    </React.Fragment>
  );
};

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => {
        return (
          <React.Fragment>
            <Styles />
            <ContentWrapper>{children}</ContentWrapper>
          </React.Fragment>
        );
      }}
    />
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

// easter egg

console.log("psst... type into your terminal: dig -t txt albertoleal.ca +short");
console.log("🤫");

export default Layout;
