import React from "react";
import { MDXProvider } from "@mdx-js/react";

import Layout from "./layout";
import SEO from "./seo";

const HomepageWrapper = props => {
  const { children } = props;

  return (
    <MDXProvider>
      <Layout>
        <SEO />
        {children}
      </Layout>
    </MDXProvider>
  );
};

export default HomepageWrapper;
