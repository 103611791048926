// 3rd-party imports

import React from "react";

// component

class ExternalWifi extends React.Component {
  state = {
    host: ""
  };

  componentDidMount() {
    this.setState({
      host: window.location.host
    });
  }

  render() {
    return (
      <a href={`http://${this.state.host}/mitm`} rel="nofollow me noopener noreferrer">
        {`I am on a public wifi 📡`}
      </a>
    );
  }
}

export default ExternalWifi;
