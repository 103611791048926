// 3rd-party imports

import React from "react";
import styled from "styled-components";

// component

const Wrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  color: #495057;

  font-size: 1.17em;
`;

const JobTitle = () => {
  return (
    <Wrapper>
      <strong>{`Software Developer in Canada`}</strong>
      <span role="img" aria-label="Canada" style={{ fontSize: "2em", marginLeft: "10px" }}>
        {`🇨🇦`}
      </span>
    </Wrapper>
  );
};

export default JobTitle;
